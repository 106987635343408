<template>
    <section class="op-bitacora-lista px-3 mt-3 overflow-auto custom-scroll">
        <div class="section-dia">
            <titulo-divisor titulo="Lunes">
                <div class="row">
                    <div class="col-auto my-auto px-1 icon-option">
                        <i class="icon-plus-box f-25 cr-pointer" @click="crearTarea" />
                    </div>
                </div>
            </titulo-divisor>
            <div class="row listado-por-dia">
                <div class="col-md-2 col-lg-1">
                    <div class="bg-f5 br-4">
                        <div class="bg-general f-12 text-white border-general br-t-4 text-center">Abr.</div>
                        <div class="f-18 f-700 d-middle-center border-bottom border-right border-left py-1">
                            29
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-dia">
            <titulo-divisor titulo="Martes">
                <div class="row">
                    <div class="col-auto my-auto px-1 icon-option">
                        <i class="icon-plus-box f-25 cr-pointer" @click="crearTarea" />
                    </div>
                </div>
            </titulo-divisor>
            <div class="row listado-por-dia">
                <div class="col-md-2 col-lg-1">
                    <div class="bg-f5 br-4">
                        <div class="bg-general f-12 text-white border-general br-t-4 text-center">Abr.</div>
                        <div class="f-18 f-700 d-middle-center border-bottom border-right border-left py-1">
                            30
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                    <div class="bg-f5 br-4 p-2 border mb-3">
                        <div class="row mx-0">
                            <div class="col-lg-7 f-12">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class="f-700">Preparar cotización</p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Tipo de actividad: 
                                            <span class="f-300"> Comercial </span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <p class=""> 7:30 am - 8:30 am </p>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <p class="f-600">
                                            Subtipo de actividad:
                                            <span class="f-300"> Marketing </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 col-lg-3 mt-2 mt-lg-0">
                                <div class="bg-white border rounded-pill d-middle tres-puntos my-1">
                                    <img src="/img/pruebas/person1.png" alt="" class="wh-26 rounded-pill bg-white">
                                    <p class="ml-2 f-12 tres-puntos">Juan Camilo Ramirez</p>
                                </div>
                            </div>
                            <div class="col-4 col-lg-2">
                                <div class="d-flex justify-content-end icon-option">
                                    <el-tooltip content="Editar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-pencil mr-2 cr-pointer f-20" @click="editarTarea" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar tarea" placement="bottom" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline mr-2 cr-pointer f-20" @click="eliminarTarea" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="row">
                            <div class="col-12">
                                <p class="f-11 lh-14">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias illo saepe sit animi, quae maxime voluptate molestias quaerat eius sed earum recusandae minima dignissimos ut officiis, ullam veritatis assumenda iure?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- partials -->
        <modal-finalizar-op ref="modalFinalizarOp" />
        <modal-crear-tarea ref="modalCrearTarea" />
        <!-- partials  -->
        <modal-eliminar ref="modalEliminarTarea" title="Eliminar etapa" mensaje="¿Está seguro que quiere eliminar esta tarea?" />
    </section>
</template>
<script>
export default {
    components:{
        modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
        modalFinalizarOp: () => import('../partials/modalFinalizarOp')
    },
    data(){
        return{
            inputSearch:'',
            tabsOportunidades: [
                {
                    titulo:'Información',
                    ruta:"oportunidades.ver.informacion",
                },
                {
                    titulo:'Productos',
                    ruta:"oportunidades.ver.productos",
                },
                {
                    titulo:'Cotizaciones',
                    ruta:"oportunidades.ver.cotizaciones",
                },
                {
                    titulo:'Bitácora',
                    ruta:"oportunidades.ver.bitacora",
                },
            ],
        }
    },
    methods:{
        crearTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        finalirOp(){
            this.$refs.modalFinalizarOp.toggle()
        },
        editarTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        eliminarTarea(){
            this.$refs.modalEliminarTarea.toggle()
        },
    }
}
</script>
<style lang="scss" scoped>
.op-bitacora-lista{
    height: calc(100vh - 355px);
    
}
</style>